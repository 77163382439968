import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Select from "react-select";
import DatePicker from "react-datepicker";
import BreadCrumb from "./BreadCrumb";
import secureLocalStorage from "react-secure-storage";
import { Link } from "react-router-dom";

import "./CareerMapping.css";

const CareerMapping = (props) => {
  const [loading, setLoading] = useState(true);
  const [cmGroups, setCMGroups] = useState([]);
  const [selectedCMGroup, setSelectedCMGroup] = useState({
    value: "",
    label: "",
  });
  const [pages, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState({});
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [cmLoading, setCMLoading] = useState(true);
  const [viewOrgano, setviewOrgano] = useState(false);
  const [organoPath, setOrganoPath] = useState("");
  const [viewJD, setviewJD] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          secureLocalStorage.getItem("userDetails")
        )}`,
      },
    };
    let path = "cm/employeecm";
    if (selectedCMGroup.value !== "") {
      path = `cm/employeecm/${selectedCMGroup.value}/0`;
    }

    axios
      .get(`${process.env.REACT_APP_API_S_LINK}/${path}`, config)

      .then(function (response) {
        if (response.status === 200) {
          if (response.data.cmcard.length >0){
            setData(response.data.cmcard[0].data);
            setPage(response.data.cmcard[0].count);
            setCurrentPage(response.data.cmcard[0].data.progression);
            setSelectedCMGroup({
              value: response.data.cmcard[0].data.cmGroupV,
              label: response.data.cmcard[0].data.cmGroupL,
            });
            setLoading(false);
            GetGroups();
          }else{
            swal("Sorry", "CareerMap is not Available", "error");
          }
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Ooh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
      });
  }, []);

  const GetGroups = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          secureLocalStorage.getItem("userDetails")
        )}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_S_LINK}/cm/groups`, config)
      .then(function (response) {
        if (response.status === 200) {
          setCMGroups(response.data.cmGroups);
          // if(selectedCMGroup.value===undefined){
          //   setSelectedCMGroup(cmGroups[0])
          // }
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Ooh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
      });
  };
  const Navigation = (direction) => {
    let cP = parseInt(currentPage);
    let pgs = parseInt(pages);
    let selectedG = selectedCMGroup.value;
    let nxt = cP + direction;
    setCMLoading(true);

    if (nxt > pgs) {
      //Disable next
      setDisableNext(true);
      return;
    } else {
      setDisableNext(false);
    }
    if (nxt <= 0) {
      //Disable prev
      setDisablePrev(true);
      return;
    } else {
      setDisablePrev(false);
    }
    if (selectedG !== "" || selectedG !== null) {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            secureLocalStorage.getItem("userDetails")
          )}`,
        },
      };
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_S_LINK}/cm/employeecm/${selectedG}/${nxt}`,
          config
        )

        .then(function (response) {
          if (response.status === 200) {
            setData(response.data.cmcard[0].data);
            setPage(response.data.cmcard[0].count);
            setCurrentPage(response.data.cmcard[0].data.progression);

            setLoading(false);
            setCMLoading(false);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            swal("Oops!", err.response.data.message, "error");
          } else {
            swal("Oops!", err.message, "error");
          }
        });
    }
  };
  //Navigation State

  //View Organo
  const DisplayOrgano = () => {
    if (selectedCMGroup.value === "") {
      return;
    }
    if (true) {
      const config = {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            secureLocalStorage.getItem("userDetails")
          )}`,
        },
      };
      let groupID = parseInt(selectedCMGroup.value);
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_S_LINK}/cm/image/${groupID}`, config)

        .then(function (response) {
          if (response.status === 200) {
            const file = new Blob([response.data], { type: "image/png" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            setOrganoPath(fileURL);
            setLoading(false);
            setviewOrgano(true);
          }
        })
        .catch((err) => {
          swal("Oops!", "Failed to fetch Roadmap Image", "error");
        });
    }
  };
  // View Job JD
  const viewJDAction= ()=>{
    props.history.push("/cm-document", [{ cm: data.no,type:0 }]);
  }
  const viewKPIAction= ()=>{
    props.history.push("/cm-document", [{ cm: data.no,type:1 }]);
  }
  if (loading) {
    return (
      <>
        <div className="container">
          <div className="headerDiv2 text-center"></div>
          <div className="jobRow">
            <div id="preloader-home">
              <div
                className="sk-three-bounce"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (viewOrgano) {
    return (
      <>
        <div className="container-organo">
          <button
            className="btn btn-secondary my-2"
            onClick={() => setviewOrgano(false)}
          >
            Close
          </button>
          {/* <h1>View Organo</h1> */}
          <div className="card rounded-2">
            <img className="img-fluid rounded-2" src={organoPath} alt="Flow" />
          </div>
        </div>
      </>
    );
  }
  
  return (
    <div className="container-careermapping">
      {/* <BreadCrumb props={props} backlink={""} /> */}
      <div className="row">
        {/* View Organo, Title and Filter groups */}
        <div className="col-md-2">
          <div className="view-organo-div">
            <i
              className="flaticon-381-blueprint view-organo-i"
              style={{ fontSize: "2rem" }}
              onClick={() => DisplayOrgano()}
            ></i>
          </div>
        </div>
        <div className="col-md-7 d-md-flex justify-content-center align-items-center">
          <div className="cmTitle">
            <h3 className="p-0 m-0">{data.title}</h3>
          </div>
        </div>
        <div className="col-md-3">
          {/* <label htmlFor="">Career Mapping Group</label> */}
          <Select
            defaultValue={selectedCMGroup}
            onChange={setSelectedCMGroup}
            options={cmGroups}
          />
        </div>
      </div>
      {/* Navigation */}
      <div className="row mt-md-4">
        <div className="col-2 text-left">
          <div
            className={`cmNavigations ${disablePrev}`}
            onClick={() => Navigation(-1)}
          >
            <span className="nav-text-drop">
              <i
                className="flaticon-381-back-2"
                style={{ fontSize: "2rem" }}
              ></i>
            </span>
          </div>
        </div>
        <div className="col-8"></div>
        <div className="col-2 text-right">
          <div
            className={`cmNavigations ${disableNext}`}
            onClick={() => Navigation(+1)}
          >
            <span className="nav-text-drop">
              <i
                className="flaticon-381-next-1"
                style={{ fontSize: "2rem" }}
              ></i>
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-md-3">
        <div className="col-md-3">
          <div className="row section-one-row">
            <div className="col-md-12">
              <div className="section-one-ttl">
                <h5 className="text-center my-3">Section One</h5>
              </div>
            </div>
            <div className="col-md-12">
              <span>Academic Qualification:</span>
              <br />
              <ul>
                {data.academicQualification.map((d, i) => {
                  return <li key={i}>{d.label}</li>;
                })}
              </ul>
              <span>Years of Practical Experience:</span>
              <ul>
                <li>{data.yearsofExp}</li>
              </ul>
            </div>
            <div className="col-md-12 text-center border job-kpi">
              <p className="p-3 m-0" onClick={()=>{viewKPIAction()}}>
                Job Key Performance Indicator
              </p>
            </div>
            <div className="col-md-6 grade">
              <p className="p-3 m-0">
                Grade:<span className="ml-2">{data.grade}</span>
              </p>
            </div>
            <div className="col-md-6 jd">
              <p className="p-3 m-0" onClick={()=>{viewJDAction()}}>
                JD:<span className="ml-2" >{data.jd}</span>
              </p>
            </div>
           
          </div>
        </div>
        <div className="col-md-9">
          <div className="row  rounded-1 mt-2 py-2">
            <div className="col-12">
              <div className="competency-section-ttl text-center">
                <h5 className="text-capitalize text-dark ">
                  competency section
                </h5>
              </div>
            </div>

            <div className="col-md-8 ">
              <div className=" core-competency-ttl text-center bg-info rounded-1">
                <div className="">
                  <h4 className="text-capitalize text-dark p-2">
                    core competency
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="core-comp-div">
                <ul className="core-comp-div-ul">
                  {data.coreCompetency.map((d, i) => {
                    if (d.label !== "") {
                      return <li key={i}>{d.label}</li>;
                    } else {
                      return "";
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-md-3">
        <div className="col-md-6 technical-comp-div">
          <div className="row">
            <div className="col-md-10">
              <div className="technical-competency-ttl text-center bg-info rounded-1">
                <div className="twin-comp">
                  <h4 className="text-capitalize text-dark p-2">
                    technical competency
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="technical-comp-div">
                <ul className="technical-comp-div-ul">
                  {data.technicalCompetency.map((d, i) => {
                    if (d.label !== "") {
                      return <li key={i}>{d.label}</li>;
                    } else {
                      return "";
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-10">
              <div className="leadership-competency-ttl text-center bg-info rounded-1">
                <div className="twin-comp">
                  <h4 className="text-capitalize text-dark p-2">
                    leadership competency
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="leadership-comp-div">
                <ul className="leadership-comp-div-ul">
                  {data.leadershipCompetency.map((d, i) => {
                    if (d.label !== "") {
                      return <li key={i}>{d.label}</li>;
                    } else {
                      return "";
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-md-4  rounded-1 bg-info ">
        <div className="col-md-12">
          <div className="compliance-courses-ttl text-center compliance-big-ttl">
            <div className="">
              <h4 className="text-capitalize text-dark p-2">
                Compliance Courses and Leardership Program Section
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row compliance-data mt-md-3">
        <div className="col-md-12">
          <div className="compliance-comp-div">
            <ul className="compliance-comp-div-ul">
              {data.complianceCourses.map((d, i) => {
                if (d.label !== "") {
                  return <li key={i}>{d.label}</li>;
                } else {
                  return "";
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CareerMapping;
