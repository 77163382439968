
import  secureLocalStorage  from  "react-secure-storage"; 
import jwt_decode from "jwt-decode";
// import { decryptToken } from "./AppUtility"; 

import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
         © {new Date().getFullYear()} {process.env.REACT_APP_NAME}
   
        </p>
      </div>
    </div>
  );
};

export default Footer;

