
import  secureLocalStorage  from  "react-secure-storage"; 
import jwt_decode from "jwt-decode";
import { decryptToken } from "./../../../../AppUtility"; 

import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "./ExitForm.css";
import axios from "axios";
import swal from "sweetalert";
import BreadCrumb from "../GlobalComponents/BreadCrumb";

const ContractCard = (props) => {
  const [loading, setLoading] = useState(true);

  const [outstanding, setOutstanding] = useState(false);
  const [aboveAverage, setAboveAverage] = useState(false);
  const [satisfactory, setSatisfactory] = useState(false);
  const [marginal, setMarginal] = useState(false);
  const [unsatisfactory, setUnsatisfactory] = useState(false);
  const [performanceComment, setPerformanceComment] = useState("");

  const [excellentAttendance, setExcellentAttendance] = useState(false);
  const [occasionalAbsence, setOccasionalAbsence] = useState(false);
  const [repeatedAbsence, setRepeatedAbsence] = useState(false);
  const [unjustifiedAbsence, setUnjustifiedAbsence] = useState(false);
  const [attendanceComment, setAttendanceComment] = useState("");

  const [alwaysInterested, setAlwaysInterested] = useState(false);
  const [reasonablyDevoted, setReasonablyDevoted] = useState(false);
  const [passiveAttitude, setPassiveAttitude] = useState(false);
  const [activeDislikeofWork, setActiveDislikeofWork] = useState(false);
  const [attitudeComment, setAttitudeComment] = useState("");

  const [alwaysNeat, setAlwaysNeat] = useState(false);
  const [generallyNeat, setGenerallyNeat] = useState(false);
  const [sometimesCareles, setSometimesCareles] = useState(false);
  const [attirenotSuitable, setAttirenotSuitable] = useState(false);
  const [appearanceComment, setAppearanceComment] = useState("");

  const [selfStarter, setSelfStarter] = useState(false);
  const [needsStimilus, setNeedsStimilus] = useState(false);
  const [needsCSupervision, setNeedsCSupervision] = useState(false);
  const [showNoInitiative, setShowNoInitiative] = useState(false);
  const [initiativeComment, setInitiativeComment] = useState("");

  const [alwayOnTime, setAlwayOnTime] = useState(false);
  const [occasionallyLate, setOccasionallyLate] = useState(false);
  const [repeatedLate, setRepeatedLate] = useState(false);
  const [rarelyOnTime, setRarelyOnTime] = useState(false);
  const [dependabilityComment, setDependabilityComment] = useState("");

  const [decisionLogical, setDecisionLogical] = useState(false);
  const [genSoundJudgment, setGenSoundJudgment] = useState(false);
  const [reqFreqCorrection, setReqFreqCorrection] = useState(false);
  const [judgmentOftenFaulty, setJudgmentOftenFaulty] = useState(false);
  const [judmentComment, setJudmentComment] = useState("");

  const [rarelyMakesErrs, setRarelyMakesErrs] = useState(false);
  const [fewErrThanMost, setFewErrThanMost] = useState(false);
  const [avgAccuracy, setAvgAccuracy] = useState(false);
  const [unacceptablyErratic, setUnacceptablyErratic] = useState(false);
  const [attentionToDetailComment, setAttentionToDetailComment] = useState("");

  const [friendlyOutgoing, setFriendlyOutgoing] = useState(false);
  const [somewhatBusinesslike, setSomewhatBusinesslike] = useState(false);
  const [gregariousToPoint, setGregariousToPoint] = useState(false);
  const [sullenAndWithdrawn, setSullenAndWithdrawn] = useState(false);
  const [interpersonalComment, setInterpersonalComment] = useState("");

  const [alwayscourteousTactful, setAlwayscourteousTactful] = useState(false);
  const [genCourteous, setGenCourteous] = useState(false);
  const [sometimesIncosiderate, setSometimesIncosiderate] = useState(false);
  const [arouseAntagonism, setArouseAntagonism] = useState(false);
  const [mannersComment, setMannersComment] = useState("");

  const [seeksAddResponsibility, setSeeksAddResponsibility] = useState(false);
  const [willinglyAcceptResp, setWillinglyAcceptResp] = useState(false);
  const [assumesWhenUnavoidable, setAssumesWhenUnavoidable] = useState(false);
  const [alwaysAvoidResponsibility, setAlwaysAvoidResponsibility] =
    useState(false);
  const [responsiblityComment, setResponsiblityComment] = useState("");

  const [graspImmediately, setGraspImmediately] = useState(false);
  const [quickerThanAvg, setQuickerThanAvg] = useState(false);
  const [avgLearning, setAvgLearning] = useState(false);
  const [slowLearner, setSlowLearner] = useState(false);
  const [unableToGraspNew, setUnableToGraspNew] = useState(false);
  const [learningCampacityComment, setLearningCampacityComment] = useState("");

  const [excepHighProductivity, setExcepHighProductivity] = useState(false);
  const [completeMoreThanAvg, setCompleteMoreThanAvg] = useState(false);
  const [adequatePerHr, setAdequatePerHr] = useState(false);
  const [inadequateOutput, setInadequateOutput] = useState(false);
  const [outputComment, setOutputComment] = useState("");

  const [assumesLeadershipInit, setAssumesLeadershipInit] = useState(false);
  const [willLeadEncouraged, setWillLeadEncouraged] = useState(false);
  const [canLeadifNecessary, setCanLeadifNecessary] = useState(false);
  const [refusesLeadership, setRefusesLeadership] = useState(false);
  const [attemptbutInefficient, setAttemptbutInefficient] = useState(false);
  const [leadershipComment, setLeadershipComment] = useState("");

  const [neverFalter, setNeverFalter] = useState(false);
  const [maintainPoise, setMaintainPoise] = useState(false);
  const [dependableExcUnderPress, setDependableExcUnderPress] = useState(false);
  const [cantTakePressure, setCantTakePressure] = useState(false);
  const [pressureComment, setPressureComment] = useState("");

  const [empStrongestpt, setEmpStrongestpt] = useState("");
  const [areaofImprovement, setAreaofImprovement] = useState("");
  const [qualifiedPromo, setQualifiedPromo] = useState("");
  const [promoPstn, setPromoPstn] = useState("");
  const [promotable, setPromotable] = useState("");
  const [effectiveWithDifferent, setEffectiveWithDifferent] = useState("");
  const [differentAssingment, setDifferentAssingment] = useState("");
  const [recommendationSectionComment, setRecommendationSectionComment] =
    useState("");
  const [empRecConfirm, setEmpRecConfirm] = useState(false);
  const [empRecExtProb, setEmpRecExtProb] = useState(false);
  const [empRecTerminate, setEmpRecTerminate] = useState(false);

  const [hrRemark, setHRRemark] = useState("");
  const [MDFDRemark, setMDFDRemark] = useState("");
  const [supportingfile, setSupportingfile] = useState("");
  const [managerRemark, setManagerRemark] = useState("");

  const [howLong, setHowlong] = useState("");
  const [doRenew, setDoRenew] = useState("");
  const [renewReason, setRenewReason] = useState("");
  const [superVisionTime, setSuperVisionTime] = useState("");

  const [immediateStatus,setImmediateStatus] = useState(false)

  const [selectedEmp, setSelectedEmp] = useState("");
  const [selectedMgr, setSelectedMgr] = useState("");
  const [skills, setSkills] = useState("");
  const [datax, setDatax] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [empWeakestPt, setEmpWeakestPt] = useState("");

  //Additional Fields
  const [jobTitle, setJobTitle] = useState("");
  const [branch, setBranch] = useState("");
  const [product, setProduct] = useState("");
  const [employmentYear, setEmploymentYear] = useState("");
  const [yearsOfService, setYearsOfService] = useState("");

  // Error alerts
  let errorsObj = { radiofield1: "", comment1: "" };
  const [alrtone, setAlrtone] = useState('alrt-border-1 border-0 border-danger p-3 rounded');
  const [alrttwo, setAlrttwo] = useState('alrt-border-2 border-0 border-danger p-3 rounded');
  const [alrtthree, setAlrtthree] = useState('alrt-border-3 border-0 border-danger p-3 rounded');
  const [alrtfour, setAlrtfour] = useState('alrt-border-4 border-0 border-danger p-3 rounded');

  const [alrtfive, setAlrtfive] = useState('alrt-border-1 border-0 border-danger p-3 rounded');
  const [alrtsix, setAlrtsix] = useState('alrt-border-2 border-0 border-danger p-3 rounded');
  const [alrtseven, setAlrtseven] = useState('alrt-border-3 border-0 border-danger p-3 rounded');
  const [alrteight, setAlrteight] = useState('alrt-border-4 border-0 border-danger p-3 rounded');
  const [alrtnine, setAlrtnine] = useState('alrt-border-4 border-0 border-danger p-3 rounded');
  const [alrtten, setAlrtten] = useState('alrt-border-4 border-0 border-danger p-3 rounded');
  const [alrteleven, setAlrtelevem] = useState('alrt-border-4 border-0 border-danger p-3 rounded');

  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(secureLocalStorage.getItem("userDetails"))
        }`,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_API_S_LINK}/endofmonitoringandcontract/contractcarddata/${props.location.state[0].datum[0].contractNo}`,
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          // => console.log(response.data);
          setSuperVisionTime(props.location.state[0].datum[0].supervisionTime)
          setDoRenew(props.location.state[0].datum[0].doRenew)
          setHowlong(props.location.state[0].datum[0].howlong)
          setRenewReason(props.location.state[0].datum[0].renewReason)

          // setJobTitle(response.data.employeeEndofForms[0].jobtitle)
          // setBranch(response.data.employeeEndofForms[0].branch)
          // setProduct(response.data.employeeEndofForms[0].product)
          // setEmploymentYear(response.data.employeeEndofForms[0].employmentyear)
          // setYearsOfService(response.data.employeeEndofForms[0].tenureofservice)


          setJobTitle(response.data.probationFirstList[0].jobtitle);
          setBranch(response.data.probationFirstList[0].branch);
          setProduct(response.data.probationFirstList[0].product);
          setEmploymentYear(response.data.probationFirstList[0].employmentyear);
          setYearsOfService(response.data.probationFirstList[0].tenureofservice);
  
          setDatax(response.data.probationFirstList[0]);
          setSelectedEmp(response.data.probationFirstList[0].employeename);
          setSkills(response.data.probationFirstList[0].skill);
          setSelectedMgr(response.data.probationFirstList[0].managername);
          setHRRemark(response.data.probationFirstList[0].hRcomment);
          setMDFDRemark(response.data.probationFirstList[0].mDcomment);
  
          setOutstanding(response.data.probationFirstList[0].outstanding);
          setAboveAverage(response.data.probationFirstList[0].aboveAverage);
          setSatisfactory(response.data.probationFirstList[0].satisfactory);
          setMarginal(response.data.probationFirstList[0].marginal);
          setUnsatisfactory(response.data.probationFirstList[0].unsatisfactory);
          setPerformanceComment(response.data.probationFirstList[0].performanceComment);
  
          setExcellentAttendance(response.data.probationFirstList[0].excellentAttendance);
          setOccasionalAbsence(response.data.probationFirstList[0].occasionalAbsence);
          setRepeatedAbsence(response.data.probationFirstList[0].repeatedAbsence);
          setUnjustifiedAbsence(response.data.probationFirstList[0].unjustifiedAbsence);
          setAttendanceComment(response.data.probationFirstList[0].attendanceComment);
  
          setAlwaysInterested(response.data.probationFirstList[0].alwaysInterested);
          setReasonablyDevoted(response.data.probationFirstList[0].reasonablyDevoted);
          setPassiveAttitude(response.data.probationFirstList[0].passiveAttitude);
          setActiveDislikeofWork(response.data.probationFirstList[0].activeDislikeofWork);
          setAttitudeComment(response.data.probationFirstList[0].attitudeComment);
  
          setAlwaysNeat(response.data.probationFirstList[0].alwaysNeat);
          setGenerallyNeat(response.data.probationFirstList[0].generallyNeat);
          setSometimesCareles(response.data.probationFirstList[0].sometimesCareles);
          setAttirenotSuitable(response.data.probationFirstList[0].attirenotSuitable);
          setAppearanceComment(response.data.probationFirstList[0].appearanceComment);
  
          setSelfStarter(response.data.probationFirstList[0].selfStarter);
          setNeedsStimilus(response.data.probationFirstList[0].needsStimilus);
          setNeedsCSupervision(response.data.probationFirstList[0].needsCSupervision);
          setShowNoInitiative(response.data.probationFirstList[0].showNoInitiative);
          setInitiativeComment(response.data.probationFirstList[0].initiativeComment);
  
          setAlwayOnTime(response.data.probationFirstList[0].alwayOnTime);
          setOccasionallyLate(response.data.probationFirstList[0].occasionallyLate);
          setRepeatedLate(response.data.probationFirstList[0].repeatedLate);
          setRarelyOnTime(response.data.probationFirstList[0].rarelyOnTime);
          setDependabilityComment(response.data.probationFirstList[0].dependabilityComment
          );
  
          setDecisionLogical(response.data.probationFirstList[0].decisionLogical);
          setGenSoundJudgment(response.data.probationFirstList[0].genSoundJudgment);
          setReqFreqCorrection(response.data.probationFirstList[0].reqFreqCorrection);
          setJudgmentOftenFaulty(response.data.probationFirstList[0].judgmentOftenFaulty);
          setJudmentComment(response.data.probationFirstList[0].judmentComment);
  
          setRarelyMakesErrs(response.data.probationFirstList[0].rarelyMakesErrs);
          setFewErrThanMost(response.data.probationFirstList[0].fewErrThanMost);
          setAvgAccuracy(response.data.probationFirstList[0].avgAccuracy);
          setUnacceptablyErratic(response.data.probationFirstList[0].unacceptablyErratic);
          setAttentionToDetailComment(response.data.probationFirstList[0].attentionToDetailComment
          );
  
          setFriendlyOutgoing(response.data.probationFirstList[0].friendlyOutgoing);
          setSomewhatBusinesslike(
          response.data.probationFirstList[0].somewhatBusinesslike
          );
          setGregariousToPoint(response.data.probationFirstList[0].gregariousToPoint);
          setSullenAndWithdrawn(response.data.probationFirstList[0].sullenAndWithdrawn);
          setInterpersonalComment(
          response.data.probationFirstList[0].interpersonalComment
          );
  
          setAlwayscourteousTactful(
          response.data.probationFirstList[0].alwayscourteousTactful
          );
          setGenCourteous(response.data.probationFirstList[0].genCourteous);
          setSometimesIncosiderate(
          response.data.probationFirstList[0].sometimesIncosiderate
          );
          setArouseAntagonism(response.data.probationFirstList[0].arouseAntagonism);
          setMannersComment(response.data.probationFirstList[0].mannersComment);
  
          setSeeksAddResponsibility(
          response.data.probationFirstList[0].seeksAddResponsibility
          );
          setWillinglyAcceptResp(response.data.probationFirstList[0].willinglyAcceptResp);
          setAssumesWhenUnavoidable(
          response.data.probationFirstList[0].assumesWhenUnavoidable
          );
          setAlwaysAvoidResponsibility(
          response.data.probationFirstList[0].alwaysAvoidResponsibility
          );
          setResponsiblityComment(
          response.data.probationFirstList[0].responsiblityComment
          );
  
          setGraspImmediately(response.data.probationFirstList[0].graspImmediately);
          setQuickerThanAvg(response.data.probationFirstList[0].quickerThanAvg);
          setAvgLearning(response.data.probationFirstList[0].avgLearning);
          setSlowLearner(response.data.probationFirstList[0].slowLearner);
          setUnableToGraspNew(response.data.probationFirstList[0].unableToGraspNew);
          setLearningCampacityComment(
          response.data.probationFirstList[0].learningCampacityComment
          );
  
          setExcepHighProductivity(
          response.data.probationFirstList[0].excepHighProductivity
          );
          setCompleteMoreThanAvg(response.data.probationFirstList[0].completeMoreThanAvg);
          setAdequatePerHr(response.data.probationFirstList[0].adequatePerHr);
          setInadequateOutput(response.data.probationFirstList[0].inadequateOutput);
          setOutputComment(response.data.probationFirstList[0].outputComment);
  
          setAssumesLeadershipInit(response.data.probationFirstList[0].assumesLeadershipInit
          );
          setWillLeadEncouraged(response.data.probationFirstList[0].willLeadEncouraged);
          setCanLeadifNecessary(response.data.probationFirstList[0].canLeadifNecessary);
          setRefusesLeadership(response.data.probationFirstList[0].refusesLeadership);
          setAttemptbutInefficient(response.data.probationFirstList[0].attemptbutInefficient
          );
          setLeadershipComment(response.data.probationFirstList[0].leadershipComment);
  
          setNeverFalter(response.data.probationFirstList[0].neverFalter);
          setMaintainPoise(response.data.probationFirstList[0].maintainPoise);
          setDependableExcUnderPress(response.data.probationFirstList[0].dependableExcUnderPress
          );
          setCantTakePressure(response.data.probationFirstList[0].cantTakePressure);
          setPressureComment(response.data.probationFirstList[0].pressureComment);
  
          setEmpStrongestpt(response.data.probationFirstList[0].empStrongestpt);
          setEmpWeakestPt(response.data.probationFirstList[0].empWeakestPt);
          setQualifiedPromo(response.data.probationFirstList[0].qualifiedPromo);
          setPromoPstn(response.data.probationFirstList[0].promoPstn);
          setPromotable(response.data.probationFirstList[0].promotable);
          setEffectiveWithDifferent(response.data.probationFirstList[0].effectiveWithDifferent
          );
          setDifferentAssingment(response.data.probationFirstList[0].differentAssingment);
          setRecommendationSectionComment(
          response.data.probationFirstList[0].recommendationSectionComment
          );

          
          setLoading(false);
        }
        if (response.status === 404) {
          swal("Oh!", response.data.message, "error");
          // => console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log({ err: err });
        swal("Oh!", err.data.message, "error");
      });

  }, []);

  const updateCard = (e) => {
    let error;
    if (recommendationSectionComment === '' || appearanceComment === "") {
      setAlrtseven('alrt-border-4 border border-danger p-3 m-2 rounded')
      error = true;
    }else{
      setAlrtseven('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
      error = false;
    }
    if (error) {
      swal("Oops!", "Immediate Supervisor Comment or Section 2 Data is missing", "error");
      return;
    }

    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(secureLocalStorage.getItem("userDetails"))
        }`,
      },
    };
    const data= {
      ContractNo:props.location.state[0].datum[0].contractNo,
      // UIDComment:managerRemark
    };

    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Update Contract Form",
      icon: "warning",
      dangerMode: true,
    })
      .then((willCreate) => {
        if (willCreate) {
          return axios.post(
            `${process.env.REACT_APP_API_S_LINK}/endofmonitoringandcontract/movecontractfromsupervisortohod`,
            data,
            config
          );
        }
      })

      .then(function (response) {
        if (response.status === 200) {
          // => console.log(response.data);
          swal("Success!", "Contact Form Updated", "success");
        }
        if (response.status === 404) {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
        console.log({ err: err });
      });
  };

  const onChangeSupportingDoc = (e) => {
    setSupportingfile(e.target.files[0]);
  };
  //Upload Upload Supporting Doc
  const uploadSupportindDoc = (e) => {
    e.preventDefault();

    if (supportingfile !== "") {
      if (supportingfile.size / 1024 > 6024) {
        alert("Size above 6MB");
        return;
      }
      if (supportingfile.type !== "application/pdf") {
        alert("File not pdf.");
        return;
      }

      const formData = new FormData();
      formData.append(`formFile`, supportingfile);
      const config = {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(secureLocalStorage.getItem("userDetails"))
          }`,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_S_LINK}/endofmonitoringandcontract/hoduploadendofcontractdocs/${props.location.state[0].datum[0].contractNo}`,
          formData,
          config
        )
        .then(function (response) {
          if (response.status === 200) {
            swal("Success!", "Your file has been Uploaded", "success");
          }
          if (response.status === 404) {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            swal("Oh!", err.response.data.message, "error");
          } else {
            swal("Oh!", err.message, "error");
          }
          console.log({ err: err });
          // swal("Oops!", "Seems like we couldn't Upload the file", "error");
        });
    }
  };

  //Push first segment
  const uploadFirstSegmentCard = (e) => {
    e.preventDefault();

    //// => console.log(`outstanding=${outstanding} aboveAverage=${aboveAverage} satisfactory=${satisfactory} marginal=${marginal} unsatisfactory=${unsatisfactory}`);
    let error=false;
    if ((outstanding === false && aboveAverage === false && satisfactory === false && marginal === false && unsatisfactory === false ) ) {
      setAlrtone('alrt-border-1 border border-danger p-3 m-2 rounded')
      // error = true;
    }else{
      setAlrtone('alrt-border-1 border-0 border-danger p-3 m-2 rounded')
      // error = false;
    }

    if ((excellentAttendance === false || occasionalAbsence === false || repeatedAbsence === false || unjustifiedAbsence === false) && (attendanceComment === "") ) {
      setAlrttwo('alrt-border-2 border border-danger p-3 m-2 rounded')
      // error = true;
    }else{
      setAlrttwo('alrt-border-2 border-0 border-danger p-3 m-2 rounded')
  
      // error = false;
    }

    if ((alwaysInterested === false || reasonablyDevoted === false || passiveAttitude === false || activeDislikeofWork === false) && (attitudeComment === "") ) {
      setAlrtthree('alrt-border-3 border border-danger p-3 m-2 rounded')
      // error = true;
    }else{
      setAlrtthree('alrt-border-3 border-0 border-danger p-3 m-2 rounded')
      // error = false;
    }

    if ((alwaysNeat === false ||  generallyNeat === false ||  sometimesCareles === false ||  attirenotSuitable === false) &&  (appearanceComment === "" )) {
      setAlrtfour('alrt-border-4 border border-danger p-3 m-2 rounded')
      // error = true;
    }else{
      setAlrtfour('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
      // error = false;
    }

    if (appearanceComment === ""|| attitudeComment === ""||attendanceComment === "") {
      error = true;
    }else{
      error = false;
    }


    if (error) {
      swal("Oops!", "Comment(s) field empty", "error");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(secureLocalStorage.getItem("userDetails"))
        }`,
      },
    };

    let data = {
      PerformanceComment: performanceComment,
      AttendanceComment: attendanceComment,
      AttitudeComment: attitudeComment,
      AppearanceComment: appearanceComment,
      InitiativeComment: initiativeComment,
      DependabilityComment: dependabilityComment,
      JudmentComment: judmentComment,
      AttentionToDetailComment: attentionToDetailComment,
      InterpersonalComment: interpersonalComment,
      MannersComment: mannersComment,
      ResponsiblityComment: responsiblityComment,
      LearningCampacityComment: learningCampacityComment,
      OutputComment: outputComment,
      LeadershipComment: leadershipComment,
      PressureComment: pressureComment,

      Outstanding: outstanding,
      AboveAverage: aboveAverage,
      Satisfactory: satisfactory,
      Marginal: marginal,
      Unsatisfactory: unsatisfactory,

      ExcellentAttendance: excellentAttendance,
      OccasionalAbsence: occasionalAbsence,
      RepeatedAbsence: repeatedAbsence,
      UnjustifiedAbsence: unjustifiedAbsence,

      AlwaysInterested: alwaysInterested,
      ReasonablyDevoted: reasonablyDevoted,
      PassiveAttitude: passiveAttitude,
      ActiveDislikeofWork: activeDislikeofWork,

      AlwaysNeat: alwaysNeat,
      GenerallyNeat: generallyNeat,
      SometimesCareles: sometimesCareles,
      AttirenotSuitable: attirenotSuitable,

      SelfStarter: selfStarter,
      NeedsStimilus: needsStimilus,
      NeedsCSupervision: needsCSupervision,
      ShowNoInitiative: showNoInitiative,

      AlwayOnTime: alwayOnTime,
      OccasionallyLate: occasionallyLate,
      RepeatedLate: repeatedLate,
      RarelyOnTime: rarelyOnTime,

      DecisionLogical: decisionLogical,
      GenSoundJudgment: genSoundJudgment,
      ReqFreqCorrection: reqFreqCorrection,
      JudgmentOftenFaulty: judgmentOftenFaulty,

      RarelyMakesErrs: rarelyMakesErrs,
      FewErrThanMost: fewErrThanMost,
      AvgAccuracy: avgAccuracy,
      UnacceptablyErratic: unacceptablyErratic,

      FriendlyOutgoing: friendlyOutgoing,
      SomewhatBusinesslike: somewhatBusinesslike,
      GregariousToPoint: gregariousToPoint,
      SullenAndWithdrawn: sullenAndWithdrawn,

      AlwayscourteousTactful: alwayscourteousTactful,
      GenCourteous: genCourteous,
      SometimesIncosiderate: sometimesIncosiderate,
      ArouseAntagonism: arouseAntagonism,

      SeeksAddResponsibility: seeksAddResponsibility,
      WillinglyAcceptResp: willinglyAcceptResp,
      AssumesWhenUnavoidable: assumesWhenUnavoidable,
      AlwaysAvoidResponsibility: alwaysAvoidResponsibility,

      GraspImmediately: graspImmediately,
      QuickerThanAvg: quickerThanAvg,
      AvgLearning: avgLearning,
      SlowLearner: slowLearner,
      UnableToGraspNew: unableToGraspNew,

      ExcepHighProductivity: excepHighProductivity,
      CompleteMoreThanAvg: completeMoreThanAvg,
      AdequatePerHr: adequatePerHr,
      InadequateOutput: inadequateOutput,

      AssumesLeadershipInit: assumesLeadershipInit,
      WillLeadEncouraged: willLeadEncouraged,
      CanLeadifNecessary: canLeadifNecessary,
      RefusesLeadership: refusesLeadership,
      AttemptbutInefficient: attemptbutInefficient,

      NeverFalter: neverFalter,
      MaintainPoise: maintainPoise,
      DependableExcUnderPress: dependableExcUnderPress,
      CantTakePressure: cantTakePressure,
    };
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Upload",
      icon: "warning",
      buttons: ["No, cancel it", "Yes, I am sure"],
      dangerMode: true,
    })
      .then((willCreate) => {
        if (willCreate) {
          return axios.post(
            `${process.env.REACT_APP_API_S_LINK}/endofmonitoringandcontract/uploadcontractsectionone/${props.location.state[0].datum[0].contractNo}`,
            data,
            config
          );
        }
      })

      .then(function (response) {
        if (response.status === 200) {
          // => console.log(response.data);
          swal("Success!", "Contract Card Updated", "success");
        }
        if (response.status === 404) {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
        console.log({ err: err });
      });
  };

  const uploadFirstSegmentSection = (e)=>{
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(secureLocalStorage.getItem("userDetails"))
        }`,
      },
    };

    let data = {
      // EmpID: selectedEmp.value,
      ContractNo: props.location.state[0].datum[0].contractNo,
      SupervisionTime: superVisionTime,
      DoRenew:doRenew,
      Howlong:howLong,
      RenewReason:renewReason,
    };
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Upload",
      icon: "warning",
      dangerMode: true,
    })
      .then((willCreate) => {
        if (willCreate) {
          return axios.post(
            `${process.env.REACT_APP_API_S_LINK}/endofmonitoringandcontract/v1/storeendofcontractfirstdata`,
            data,
            config
          );
        }
      })

      .then(function (response) {
        if (response.status === 200) {
          // => console.log(response.data);
          swal("Success!", "Probation Card Updated", "success");
        }
       
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
        console.log({ err: err });
      });

  }

  const uploadRecomSection = (e) => {
    e.preventDefault();

    let error;
    if ((empStrongestpt === '')) {
      setAlrtfive('alrt-border-4 border border-danger p-3 m-2 rounded')
      // error = true;
    }else{
      setAlrtfive('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
      // error = false;
    }
    if ((areaofImprovement === '')) {
      setAlrtsix('alrt-border-4 border border-danger p-3 m-2 rounded')
      // error = true;
    }else{
      setAlrtsix('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
      // error = false;
    }
    if ((recommendationSectionComment === '')) {
      setAlrtseven('alrt-border-4 border border-danger p-3 m-2 rounded')
      // error = true;
    }else{
      setAlrtseven('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
      // error = false;
    }

    if(qualifiedPromo ===""){
      setAlrteight('alrt-border-4 border border-danger p-3 m-2 rounded')
    }else{
      setAlrteight('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
    }

    if(promotable ===""){
      setAlrtnine('alrt-border-4 border border-danger p-3 m-2 rounded')
    }else{
      setAlrtnine('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
    }

    if(effectiveWithDifferent ===""){
      setAlrtten('alrt-border-4 border border-danger p-3 m-2 rounded')
    }else{
      setAlrtten('alrt-border-4 border-0 border-danger p-3 m-2 rounded')
    }

    //areaofImprovement === '' || areaofImprovement === '' ||
    if (recommendationSectionComment === ''||qualifiedPromo ===""||promotable ===""||effectiveWithDifferent ==="") {
      error = true;
    }else{
      error = false;
    }


    if (error) {
      swal("Oops!", "Comment(s) field empty", "error");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(secureLocalStorage.getItem("userDetails"))
        }`,
      },
    };

    let data = {
      EmployeeStrongestPoint: empStrongestpt,
      EmployeeWeakestPoint: empWeakestPt, //area of improvement
      EmployeeQualifiedForPromo: qualifiedPromo,
      PromoPosition: promoPstn,
      PromotableInTheFuture: promotable,
      EffectiveDifferentAssignment: effectiveWithDifferent,
      WhichAssignment: differentAssingment,
      AdditionalComment: recommendationSectionComment,
      confirm: empRecConfirm,
      Extend: empRecExtProb,
      Terminate: empRecTerminate,
    };
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Upload",
      icon: "warning",
      dangerMode: true,
    })
      .then((willCreate) => {
        if (willCreate) {
          return axios.post(
            `${process.env.REACT_APP_API_S_LINK}/endofmonitoringandcontract/uploadcontractrecommendation/${props.location.state[0].datum[0].contractNo}`,
            data,
            config
          );
        }
      })

      .then(function (response) {
        if (response.status === 200) {
          // => console.log(response.data);
          swal("Success!", "Probation Card Updated", "success");
        }
        if (response.status === 404) {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
        console.log({ err: err });
      });
  };

  let btnUP = "";
  let sectionOne = "";
  let reversePath="";
  let authUser= "";
  if (jwt_decode(decryptToken(secureLocalStorage.getItem("userDetails"))).Role.length > 0) {
    authUser = jwt_decode(decryptToken(secureLocalStorage.getItem("userDetails"))).Role;
  }

  if (props.location.state[0].datum[0].status === "Open"  && props.location.state[0].datum[0].contractStatus !== 10 ) {
    btnUP = (
      <button className="btn btn-success" onClick={updateCard}>
        Push the Form
      </button>
    );
    sectionOne = (
      <button className="btn btn-warning" onClick={uploadFirstSegmentCard}>
        Upload this section
      </button>
    );
    reversePath="contract-list";
  } else if (props.location.state[0].datum[0].status === "Approved") {
    btnUP = <button className="btn btn-secondary">Form Pushed Already</button>;
    reversePath="contract-list";
  }else if(props.location.state[0].datum[0].contractStatus === 10){
    btnUP = <button className="btn btn-secondary">Bucketed Already</button>;
    if(authUser !=="HEAD-HR"){
      reversePath="bucketed-contracts";
    }else{
      reversePath="bucketed-contracts-head";
    }
  }

  if (loading) {
    return (
      <>
        <div className="container">
          <div className="headerDiv2 text-center">
            <h4 className="">Employee End Of Contract Card Loading</h4>
          </div>
          <div className="jobRow">
            <div id="preloader-home">
              <div
                className="sk-three-bounce"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
     <BreadCrumb props={props} backlink={reversePath} />{/*  */}
      <h4 className="text-center">END OF CONTRACT REPORT</h4>
      <div className="card">
        <Accordion defaultActiveKey={["-1"]} alwaysOpen>
          <Accordion.Item eventKey="-1">
            <Accordion.Header>
              <div className="title mb-4">
                <span className="fs-18 text-black font-w600">
                  New Contract Creation Card
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Employee Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.location.state[0].datum[0].empID}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Employee Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.location.state[0].datum[0].empName}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Job Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={jobTitle}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Branch</label>
                    <input
                      type="text"
                      className="form-control"
                      value={branch}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Product</label>
                    <input
                      type="text"
                      className="form-control"
                      value={product}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Year of Employment</label>
                    <input
                      type="text"
                      className="form-control"
                      value={employmentYear}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Tenure of Service</label>
                    <input
                      type="text"
                      className="form-control"
                      value={yearsOfService}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor=""> How long have you supervised</label>
                    <textarea
                      className="form-control"
                      cols="30"
                      rows="3"
                      name="Howlongs"
                      placeholder="How long have you been supervising this employee? (max 240 characters)"
                      value={superVisionTime}
                      onChange={(e) => setSuperVisionTime(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="">Do we renew the contract?</label>
                    <select
                      name="qualifiedForPromo"
                      id=""
                      className="form-control"
                      onChange={(e) => setDoRenew(e.target.value)}
                      value={doRenew}
                    >
                      <option>Choose</option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="">If yes, for how long?</label>
                    <select
                      name="qualifiedForPromo"
                      id=""
                      className="form-control"
                      onChange={(e) => setHowlong(e.target.value)}
                      value={howLong}
                    >
                      <option>Choose</option>
                      <option value="1 Month">1 Months</option>
                      <option value="3 Months">3 Months</option>
                      <option value="6 Months">6 Months</option>
                      <option value="12 Months">12 Months</option>
                      <option value="24 Months">24 Months</option>
                      <option value="36 Months">36 Months</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="">Reason for renewal</label>
                    <input
                      type="text"
                      className="form-control"
                      name="probationTime"
                      placeholder="Reason(s) for renewal/non-renewal"
                      value={renewReason}
                      onChange={(e) => setRenewReason(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      className="btn btn-warning"
                      onClick={uploadFirstSegmentSection}
                    >
                      Upload this section
                    </button>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="title mb-4">
                <span className="fs-18 text-black font-w600">
                  Please tick next to the description which best applies to this
                  employee.
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
            <div className="row">
                <div className="col-md-6">
                    <div className= {alrtone}  >
                        <div className="form-group">
                          
                          <label foo="" className="b-2">
                            Overall Performance
                          </label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Outstanding"
                              id="outstanding1"
                              value={outstanding}
                              checked={outstanding}
                              onChange={(e) => setOutstanding(!outstanding)}
                            />
                            <label className="form-check-label" foo="outstanding1">
                              Outstanding
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Above Averange"
                              id="aboveAvg1"
                              checked={aboveAverage}
                              value={aboveAverage}
                              onChange={(e) => setAboveAverage(!aboveAverage)}
                            />
                            <label className="form-check-label" foo="aboveAvg1">
                              Above Averange
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Satisfactory"
                              id="satisfactory1"
                              value={satisfactory}
                              checked={satisfactory}
                              onChange={(e) => setSatisfactory(!satisfactory)}
                            />
                            <label className="form-check-label" foo="satisfactory1">
                              Satisfactory
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Marginal"
                              id="marginal1"
                              value={marginal}
                              checked={marginal}
                              onChange={() => setMarginal(!marginal)}
                            />
                            <label className="form-check-label" foo="marginal1">
                              Marginal
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Unsatisfactory"
                              id="unsatisfactory"
                              value={unsatisfactory}
                              checked={unsatisfactory}
                              onChange={() => setUnsatisfactory(!unsatisfactory)}
                            />
                            <label className="form-check-label" foo="unsatisfactory">
                              Unsatisfactory
                            </label>
                          </div>

                        </div>
                    </div>
               
                </div>
                <div className="col-md-6">
                  <div className= {alrttwo}  >
                    <div className="form-group">
                      <label foo="" className="b-2">
                        Attendance
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="excellentAttendance"
                          id="excattance"
                          checked={excellentAttendance}
                          value={excellentAttendance}
                          onChange={(e) =>
                            setExcellentAttendance(!excellentAttendance)
                          }
                        />
                        <label className="form-check-label" foo="excattance">
                          Excellent attendance record
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="attendane"
                          id="abjecjustice"
                          checked={occasionalAbsence}
                          value={occasionalAbsence}
                          onChange={(e) =>
                            setOccasionalAbsence(!occasionalAbsence)
                          }
                        />
                        <label className="form-check-label" foo="abjecjustice">
                          Occasional absence but justified
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="attendane"
                          id="absjusticc"
                          checked={repeatedAbsence}
                          value={repeatedAbsence}
                          onChange={(e) => setRepeatedAbsence(!repeatedAbsence)}
                        />
                        <label className="form-check-label" foo="absjusticc">
                          Repeated absence but justified
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="attendane"
                          id="unjustabsent"
                          checked={unjustifiedAbsence}
                          value={unjustifiedAbsence}
                          onChange={(e) =>
                            setUnjustifiedAbsence(!unjustifiedAbsence)
                          }
                        />
                        <label className="form-check-label" foo="unjustabsent">
                          Unjustified absences
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="w-100 form-control"
                        name="appearanceComment"
                        rows="2"
                        placeholder="Comment"
                        value={attendanceComment}
                        onChange={(e) => setAttendanceComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className= {alrtthree}  >
                    <div className="form-group">
                      <label foo="" className="b-2">
                        Attitude
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="alwaysInterested"
                          id="intenthusiastic"
                          checked={alwaysInterested}
                          value={alwaysInterested}
                          onChange={(e) => setAlwaysInterested(!alwaysInterested)}
                        />
                        <label className="form-check-label" foo="intenthusiastic">
                          Always interested and enthusiastic
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="reasonablyDevoted"
                          id="deveotedwrk"
                          checked={reasonablyDevoted}
                          value={reasonablyDevoted}
                          onChange={(e) =>
                            setReasonablyDevoted(!reasonablyDevoted)
                          }
                        />
                        <label className="form-check-label" foo="deveotedwrk">
                          Reasonably devoted to work
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="attitude"
                          id="passiveatt"
                          checked={passiveAttitude}
                          value={passiveAttitude}
                          onChange={(e) => setPassiveAttitude(!passiveAttitude)}
                        />
                        <label className="form-check-label" foo="passiveatt">
                          Passive attitude toward work
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="attitude"
                          id="dislwork"
                          checked={activeDislikeofWork}
                          value={activeDislikeofWork}
                          onChange={(e) =>
                            setActiveDislikeofWork(!activeDislikeofWork)
                          }
                        />
                        <label className="form-check-label" foo="dislwork">
                          Shows active dislike of work
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="w-100 form-control"
                        name="attitudeComment"
                        rows="2"
                        placeholder="Comment"
                        value={attitudeComment}
                        onChange={(e) => setAttitudeComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className= {alrtfour}  >
                    <div className="form-group">
                      <label foo="" className="b-2">
                        Appearance
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="appearance"
                          id="appropdressd"
                          checked={alwaysNeat}
                          value={alwaysNeat}
                          onChange={(e) => setAlwaysNeat(!alwaysNeat)}
                        />
                        <label className="form-check-label" foo="appropdressd">
                          Always neat and appropriately dressed
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="generallyNeat"
                          id="genapproderss"
                          checked={generallyNeat}
                          value={generallyNeat}
                          onChange={(e) => setGenerallyNeat(!generallyNeat)}
                        />
                        <label className="form-check-label" foo="genapproderss">
                          Generally neat appropriately dressed
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="sometimesCareles"
                          id="somejtapp"
                          checked={sometimesCareles}
                          value={sometimesCareles}
                          onChange={(e) => setSometimesCareles(!sometimesCareles)}
                        />
                        <label className="form-check-label" foo="somejtapp">
                          Sometimes careless about appearance
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="attirenotSuitable"
                          id="ErPosition"
                          checked={attirenotSuitable}
                          value={attirenotSuitable}
                          onChange={(e) =>
                            setAttirenotSuitable(!attirenotSuitable)
                          }
                        />
                        <label className="form-check-label" foo="ErPosition">
                          Attire not suitable for position
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="w-100 form-control"
                        name="appearanceComment"
                        rows="2"
                        placeholder="Comment"
                        value={appearanceComment}
                        onChange={(e) => setAppearanceComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">{sectionOne}</div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="title mb-4">
                <span className="fs-18 text-black font-w600">
                  Recommendations
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-md-6">
                  <div className= {alrtfive}  >
                    <div className="form-group">
                      <label foo="">
                        What do you consider to be the employee’s strongest
                        points?
                      </label>
                      <textarea
                        className="w-100 form-control"
                        name="empStrongestpt"
                        rows="2"
                        placeholder="Summary 240 characters"
                        value={empStrongestpt}
                        onChange={(e) => setEmpStrongestpt(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                <div className= {alrtsix}  >
                    <div className="form-group">
                      <label foo="">
                        What areas do you feel the employee needs to improve on?
                      </label>
                      <textarea
                        className="w-100 form-control"
                        name="empWeakestPt"
                        rows="2"
                        placeholder="Summary 240 characters"
                        value={empWeakestPt}
                        onChange={(e) => setEmpWeakestPt(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className={alrteight}>
                    <div className="form-group">
                      <label foo="">
                        Do you consider the employee to be qualified for promotion
                        at the present time?{" "}
                      </label>
                      <select
                        name="qualifiedForPromo"
                        id=""
                        className="form-control"
                        onChange={(e) => setQualifiedPromo(e.target.value)}
                      >
                        <option>Choose</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label foo="">If yes, to what position? </label>
                    <input
                      type="text"
                      className="form-control"
                      name="promoPstn"
                      value={promoPstn}
                      onChange={(e) => setPromoPstn(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className={alrtnine}>
                    <div className="form-group">
                      <label foo="">
                        If no, do you think the employee may be promotable at a
                        future date?
                      </label>
                      <select
                        name="promotable"
                        id=""
                        className="form-control"
                        onChange={(e) => setPromotable(e.target.value)}
                      >
                        <option>Choose</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                 
                </div>

                <div className="col-md-3">
                  <div className={alrtten}>
                    <div className="form-group">
                      <label foo="">
                        Do you think the employee would be more effective with a
                        different assignment than the present one?
                      </label>
                      <select
                        name="promotable"
                        id=""
                        className="form-control"
                        onChange={(e) =>
                          setEffectiveWithDifferent(e.target.value)
                        }
                      >
                        <option>Choose</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
              
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label foo="">If yes, which one? </label>
                    <input
                      type="text"
                      className="form-control"
                      name="differentAssingment"
                      value={differentAssingment}
                      onChange={(e) => setDifferentAssingment(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                <div className= {alrtseven}  >
                  <div className="form-group">
                  <label foo="">
                    <b> Immediate Supervisor Recommendation</b> 
                    </label>
                    <textarea
                      className="w-100 form-control"
                      name="recommendationSectionComment"
                      rows="2"
                      placeholder="Comment (max 240 characters)"
                      value={recommendationSectionComment}
                      onChange={(e) =>
                        setRecommendationSectionComment(e.target.value)
                      }
                    ></textarea>
                  </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor=""> Supporting Documents</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={onChangeSupportingDoc}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="">Action</label> <br />
                  <button
                    className="btn btn-primary rounded-0"
                    onClick={uploadSupportindDoc}
                  >
                    Upload Supporting File{" "}
                    <i className="fa fa-cloud-upload"></i>
                  </button>
                  <button
                    className="btn btn-warning rounded-0"
                    onClick={uploadRecomSection}
                  >
                    Upload this Section
                  </button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="title mb-4">
                <span className="fs-18 text-black font-w600">
                  Human Resource and MD/FD Section
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
              {/* <div className="col-md-12">
                  <div className="form-group">
                    <label foo="">
                      Immediate Supervisor
                    </label>
                    <textarea
                      className="w-100 form-control"
                      name="managerRemark"
                      rows="2"
                      placeholder="Summary 240 characters"
                      value={managerRemark}
                      onChange={(e) => setManagerRemark(e.target.value)}
                    ></textarea>
                      {immediateStatus && (
                            <div className="text-danger fs-12">
                              This field is Mandatory
                            </div>
                          )}
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label foo="">HR Remarks</label>
                    <textarea
                      className="w-100 form-control"
                      name="hrRemark"
                      rows="2"
                      placeholder="Summary 240 characters"
                      value={hrRemark}
                      onChange={(e) => setHRRemark(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label foo="">MD/FD Remarks</label>
                    <textarea
                      className="w-100 form-control"
                      name="MDFDRemark"
                      rows="2"
                      placeholder="Summary 240 characters"
                      value={MDFDRemark}
                      onChange={(e) => setMDFDRemark(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="card-footer">
          <div className="text-right">{btnUP}</div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ContractCard);

